import React from "react";

const Index = React.lazy(() => import("./pages/index"));
const TOS = React.lazy(() => import("./pages/Tos"));
const Docs = React.lazy(() => import("./pages/docs/Docs"));
const Guides = React.lazy(() => import("./pages/Guides"));
const Faqs = React.lazy(() => import("./pages/Faqs"));
const Login = React.lazy(() => import("./pages/Login"));
const Register = React.lazy(() => import("./pages/Register"));

const routes = [
  { path: "/", component: Index },
  { path: "/tos", component: TOS},
  { path: "/docs", component: Guides },
  { path: "/faqs", component: Faqs },
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/docs/:name", component: Docs },
];

export default routes;
